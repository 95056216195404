:root{
    --primaryColor: #017d3d;
    --secondaryColor: #F0F7EE;
    --white:#ffffff;
    --fontFamily: "Nunito";
  }
.sectionOne{
    display: flex;
    width: 100%;
    padding: 8% 12%;
}
.headText{
    font-size: 70px;
}
.headText:nth-child(2){
    position: relative;
    top: -25px;
}
.boxOne{
    width:70%;
}
.boxTwo{
    position: relative;
    bottom: -50px;
    width:30%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.estateBoxOne{
    width:100%;
    padding-right: 35%!important;
}
.estateBoxTwo{
    width:100%;
}
.dot{
    width:20px;
    height: 20px;
    background-color: var(--primaryColor);
    border-radius:20px;
}
.lineP{
    height: 70%;
}
.line{
    /* position: relative;
    top: 10%;
    right: 20%; */
    margin-top: 20px;
    width: 2px;
    height: 100%;
    background-color: #aaa;
}
.sectionTwo{
    position: relative;
    top: -80px;
    display: flex;
    width: 100%;
    padding-left:12%;
}
.secTwoDivOne{
   width: 10%;
   height: 100%;
   display: flex;
   justify-content: center;
}
.lineTwo{
    width: 2px;
    height: 650px;
    background-color: #aaa;
}
.secTwoDivTwo{
    position: relative;
    top: 0;
    right: 0;
    width:90%;
}
.imgDiv{
    width: 100%;
    margin-top: 2%;
    overflow: hidden;
}
.imgDivTwo{
    width: 100%;
}
.one{
    width: 100%;
    padding: 2% 5% 0 0; 
}
.two{
    position: relative;
    top: -150px;
    right: 0;
    width: 50%;
    height: 50vh;
    background-color: rgb(152, 152, 152);
    overflow: hidden;
}
.sectionThree{
    width: 100%;
    padding:5% 12% 0;
    position: relative;
    top: -80px;
}
.sectionTh{
    display: flex;
    width: 100%;
}
.sectionThreeDiv{
    width: 35%;
}
.paymentPlan{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
}
.sectionThreeDivTwo{
    position: relative;
    top: -100px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: flex-end;
}
.btnGetInTouch{
    cursor: pointer;
    position: relative;
    bottom: 0px;
    right: -4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryColor);
    border-radius: 50px;
    padding: 2px;
    width: 50px;
    height: 50px;
}
.btnGetInTouch::before{ 
    position: relative;
    bottom: -40%;
    right: 250%;
    content: " ";
    width: 150px;
    height: 2px;
    background-color: #aaa;
}
.touchText{
    font-size: 12px!important;
    color: #ffffff;
}
.touchText:nth-child(2){
    position: relative;
    top: -5px;
}
.touchLine{
    position: relative;
    top: -15px;
    right: 0;
    width: 2px;
    height: 150px;
    background-color: #aaa;
}
.sectionFour{
    padding:0 20% 5% 20%; 
}

@media only screen and (max-width: 1280px){
    .headText {
        font-size: 40px;
    }

}
@media only screen and (max-width: 992px){
    .sectionOne{
        padding: 4% 12%;
    }
    .headText {
        font-size: 35px;
    }
    .paragraphTitle {
        font-size: 15px;
    }
    .sectionTwo{
        top: -45px;
    }
    .lineTwo {
        height: 450px;
    }
    .sectionThree{
        padding: 10% 12% 0;
    }
    .notFound{
        color:#000; 
        margin-top:20px;font-size:20px; 
        align-self:center
    }
}
@media only screen and (max-width: 540px){
    .sectionOne {
        padding: 4% 8%;
        display: block;
    }
    .headText {
        font-size: 35px;
    }
    .headText:nth-child(2) {
        top: -11px;
    }
    #textH{
        top: -11px;
        font-size: 40px;
    }
    .lineP {
        height: 70px;
    }
    .boxOne {
        width: 100%;
    }
    .boxTwo {
        bottom: -14px;
        width: 100%;
    }
    .sectionTwo {
        position: inherit;
        padding-left: 10%;
        padding-top: 10%;
    }
    .sectionThree {
        position: inherit;
        padding-left: 8%;
    }
    .sectionTh {
        display: block;
    }
    .sectionThreeDiv {
        width: 80%;
    }
    .sectionThreeDivTwo{
        top: -80px;
        left: 45%;
    }
    .sectionFour{
        padding: 0 10% 5% 10%;
    } 
    .paragraphTitle {
        font-size: 14px;
     }
     .notFound{
        font-size:14px; 
     }
     .paymentPlan h5{
       font-size: 14px;
     }
}
@media only screen and (max-width:420px){
   
    #textH{
        font-size: 40px;
    }
    .secTwoDivOne{
        display: none;
    }
    .secTwoDivTwo{
        width: 100%;
    }

}

@media only screen and (max-width:310px){
    .paragraphTitle {
        font-size: 12px;
    }
    .feat{
        font-size: 12px!important;
    }

}
