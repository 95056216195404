:root{
    --primaryColor: #017d3d;
    --secondaryColor: #F0F7EE;
    --white:#ffffff;
    --fontFamily: "Nunito";
  }

.sectionOne{
    display: flex;
    width: 100%;
    padding: 8% 12%;
}
.headText{
    font-size: 70px;
}
.headText:nth-child(2){
    position: relative;
    top: -5px;
}
.headText:nth-child(3){
    position: relative;
    top: -25px;
}
.boxOne{
    width:70%;
}
.boxTwo{
    position: relative;
    bottom: -20px;
    width:30%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.dot{
    width:20px;
    height: 20px;
    background-color: var(--primaryColor);
    border-radius:20px;
}
.lineP{
    height: 150px;
}
.line{
    margin-top: 20px;
    width: 2px;
    height: 100%;
    background-color: #aaa;
}
.eventParent{
    position: relative;
    top: -150px;
    padding: 0 12%;
    width: 100%;
}
.events{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height:auto;
}
.event{
    width: 30%;
    margin-right:3%;
    background-color: #aaa;
    height:450px;
    border-radius: 15px;
    text-decoration: none;
}
.event:nth-child(1) {
    margin-right: 3%;
    margin-top: 5%;
}
.event:nth-child(2) {
    margin-right: 3%;
    margin-top: 10%;
}
.event:nth-child(3) {
    margin-right: 3%;
    margin-top: 15%;
}
.eventImg{
   border-radius: 15px;
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.eventDetails{
    display: flex;
    flex-direction: column;
    justify-content:center;
    /* align-items:center; */
    width: 100%;
    height: 100px;
    padding:0 5%;
    position: relative;
    bottom: 100px;
    z-index: 500;
    border-radius: 15px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
}
.eventTitle{
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}
.eventDate{
    position: relative;
    top: -15px;
    font-size: 12px;
    color: #f0f0f0;
}
.eventDate .after{
    content: "";
    font-size: 12px;
    width: 100%;
    height: 4px;
    background-color:#f0f0f0;
}
.detailPage{
    padding: 5% 12%;
    /* background-color:#3d3d3d; */
}
.eventBox{
    display: flex;
    align-items: flex-end;
    margin-bottom: 5%;
}
.lineDiv{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    position: relative;
    top: -20px;
}
.galleryLine{
    width: 80px;
    height: 2px;
    background-color: #333;
    margin-left:20px;
    margin-right:10px;
}
.galleryParent{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.galleryText{
    color: #333;
}
.picture{
    background-color: #017d3d;
    height: 450px;
    width: 32%;
    border-radius: 20px;
}




@media only screen and (max-width: 1280px){

}
@media only screen and (max-width: 992px){
    .sectionOne{
        padding: 8% 10%;
    }
    .eventParent {
        top: -50px;
        padding: 0 8%;
    }
    .event {
        width: 45%;
        margin-right: 3%;
        /* margin-top: 10%; */
    }
    .event:nth-child(even) {
        margin-right: 0px;
        margin-top: 10%;
    }
    .eventBox{
        display: block;
    }
    .headText{
        margin-bottom: 8%;
    }

}
@media only screen and (max-width: 540px){
    .sectionOne {
        display: block;
        width: 100%;
        padding: 5% 10%;
    }
    .boxOne {
        width: 100%;
    }
    .boxTwo{
        bottom: -20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

.headText{
    font-size: 35px;
}
.boxTwo {
    bottom: 40px;
}
.headText:nth-child(2){
    position: relative;
    top: -20px;
}
.headText:nth-child(3){
    position: relative;
    top: -45px;
}
    .paragraphTitle {
        font-size: 14px;
    }
    .event {
        width: 100%;
        margin-right: 0;
    }
    .line{
        margin-top: 10px;
        width: 2px;
        height: 40%;
    }

}
/* @media only screen and (max-width:390px){
   
 

} */

@media only screen and (max-width:310px){
   

.headText{
    font-size: 25px;
}
    .paragraphTitle {
        font-size: 15px;
    }
    .event {
        width: 100%;
        margin-right: 0;
    }
    .line{
        margin-top: 10px;
        width: 2px;
        height: 40%;
    }

}